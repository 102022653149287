<template>
  <div class="master-toko">
    <header-page>
      <span class="size14 fw-bold-700" style="color: #0b5fff; margin-right: 5px"
        >Master</span
      >
      <span class="text-dark text-darken-5" style="margin-right: 5px">></span>
      <span class="text-dark fw-bold-400 size14">Cabang</span>

      <!-- <template v-slot:action>
        <div class="w-75 d-flex justify-content-end mr-2">
          <p class="mb-0 fw-bold-500 size16 text-dark">
            Daftar Cabang ({{ result.data.data.length }} /
            {{ result.data.data.length + slotAddBranch }})
          </p>
        </div>
      </template> -->
    </header-page>

    <Table
      :result="result"
      :delete-data="deleteData"
      :slot-add-branch="slotAddBranch"
      :clean-up-form="cleanUpForm"
      :get-data="getData"
      :edit-item="editItem"
      @filterBank="getFilter"
      :isLoading="isLoading"
    />

    <Modal
      :result="result"
      :form-data="formPayload"
      :create-item="createItem"
      :user-list="userList"
      :country-list="countryList"
      :search-country="searchCountry"
      :searchQuery="searchQuery"
      :edit-id="editId"
      :edit-item="editItem"
      :update-item="updateItem"
      :clean-up-form="cleanUpForm"
      :photo="photo"
      :messages="messages"
      :isLoading="isLoading"
      @getPayload="getPayload"
      @update:selectedLocation="updateSelectedLocation"
    />
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from "lodash";
import { VBToggle } from "bootstrap-vue";
import {
  checkPermission,
  successNotification,
  errorNotification,
} from "@/auth/utils";

import HeaderPage from "@/components/HeaderPage.vue";
import Modal from "@/components/Master/Cabang/Modal/Modal.vue";
import Table from "@/components/Master/Cabang/Table.vue";

export default {
  title() {
    return "Master Cabang";
  },
  components: {
    HeaderPage,
    Table,
    Modal,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {
      currentPage: 1,
      isLoading: true,
      result: {},
      validations: [],
      editId: null,
      photo: null,
      userList: [],
      formPayload: {
        name: "",
        city: "",
        photo: "",
        address: "",
        phone: "",
        phone_country: "",
        postal_code: "",
        address_latitude: "",
        address_longitude: "",
        display_vat_on_order_invoice: false,
        allow_negative_stock: false,
        display_stock_ecommerce: false,
        enable_petty_cash: false,
        selectedCountry: {
          country_code: "ID",
          country_flag: "https://flagsapi.com/ID/shiny/64.png",
          country_name: "Indonesia",
          dial_code_preview: "+62",
          dial_code_value: "62",
        },
        petty_cash_permissions: [
          {
            operator_id: "",
          },
        ],
        petty_cash_seperate: "",
        operational_hours: [
          {
            day: "",
            opening_time: "",
            closing_time: "",
            is_holiday: false,
          },
          {
            day: "",
            opening_time: "",
            closing_time: "",
            is_holiday: false,
          },
          {
            day: "",
            opening_time: "",
            closing_time: "",
            is_holiday: false,
          },
          {
            day: "",
            opening_time: "",
            closing_time: "",
            is_holiday: false,
          },
          {
            day: "",
            opening_time: "",
            closing_time: "",
            is_holiday: false,
          },
          {
            day: "",
            opening_time: "",
            closing_time: "",
            is_holiday: false,
          },
          {
            day: "",
            opening_time: "",
            closing_time: "",
            is_holiday: false,
          },
        ],
      },
      filter: {
        search: "",
        per_page: 10,
        sort_type: "asc",
      },
      countryList: [],
      slotAddBranch: "",
      messages: {},
      searchQuery: "",
    };
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    };
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData();
      }, 300),
      deep: true,
    },
  },
  created() {
    this.getData();
  },
  methods: {
    updateSelectedLocation(value) {
      this.selectedLocation = value; // Update the selectedLocation data property
    },
    getPayload(value) {
      this.formPayload = value;
    },
    getFilter(value) {
      this.filter = value;
    },
    cleanUpForm() {
      this.editId = null;
      this.photo = null;
      this.formPayload = {
        name: "",
        city: "",
        photo: "",
        address: "",
        phone: "",
        phone_country: "",
        postal_code: "",
        address_latitude: "",
        address_longitude: "",
        display_vat_on_order_invoice: false,
        allow_negative_stock: false,
        display_stock_ecommerce: false,
        enable_petty_cash: "0",
        petty_cash_seperate: "",
        selectedCountry: {
          country_code: "ID",
          country_flag: "https://flagsapi.com/ID/shiny/64.png",
          country_name: "Indonesia",
          dial_code_preview: "+62",
          dial_code_value: "62",
        },
        operational_hours: [
          {
            day: "sunday",
            opening_time: "",
            closing_time: "",
            is_holiday: false,
          },
          {
            day: "monday",
            opening_time: "",
            closing_time: "",
            is_holiday: false,
          },
          {
            day: "tuesday",
            opening_time: "",
            closing_time: "",
            is_holiday: false,
          },
          {
            day: "wednesday",
            opening_time: "",
            closing_time: "",
            is_holiday: false,
          },
          {
            day: "thursday",
            opening_time: "",
            closing_time: "",
            is_holiday: false,
          },
          {
            day: "friday",
            opening_time: "",
            closing_time: "",
            is_holiday: false,
          },
          {
            day: "saturday",
            opening_time: "",
            closing_time: "",
            is_holiday: false,
          },
        ],
        petty_cash_permissions: [
          {
            operator_id: "",
          },
        ],
      };
      this.validations = "";
    },
    editItem(item) {
      this.messages = {};
      // this.cleanUpForm();
      this.editId = item.uuid;
      this.photo = item.photo_url;
      this.$store.commit(
        "cabang/setFormPayload",
        (this.formPayload = {
          name: item.name,
          city: item.city,
          address: item.address,
          code_branch: item.code_branch,
          phone: item.phone,
          selectedCountry: item.phone_country,
          postal_code: item.postal_code,
          address_latitude: item.address_latitude,
          address_longitude: item.address_longitude,
          allow_negative_stock: item.allow_negative_stock === true ? 1 : 0,
          display_stock_ecommerce:
            item.display_stock_ecommerce === true ? 1 : 0,
          display_vat_on_order_invoice:
            item.display_vat_on_order_invoice === true ? 1 : 0,
          enable_petty_cash: item.enable_petty_cash === true ? "1" : "0",
          order_invoice_notes: item.order_invoice_notes,
          petty_cash_permissions: item.petty_cash_permissions,
          petty_cash_seperate:
            item.petty_cash_permissions?.length > 0 ? "yes" : "no",
          operational_hours: [
            {
              day: item.operational_hours[0].day,
              opening_time: item.operational_hours[0].opening_time,
              closing_time: item.operational_hours[0].closing_time,
              is_holiday: item.operational_hours[0].is_holiday,
            },
            {
              day: item.operational_hours[1].day,
              opening_time: item.operational_hours[1].opening_time,
              closing_time: item.operational_hours[1].closing_time,
              is_holiday: item.operational_hours[1].is_holiday,
            },
            {
              day: item.operational_hours[2].day,
              opening_time: item.operational_hours[2].opening_time,
              closing_time: item.operational_hours[2].closing_time,
              is_holiday: item.operational_hours[2].is_holiday,
            },
            {
              day: item.operational_hours[3].day,
              opening_time: item.operational_hours[3].opening_time,
              closing_time: item.operational_hours[3].closing_time,
              is_holiday: item.operational_hours[3].is_holiday,
            },
            {
              day: item.operational_hours[4].day,
              opening_time: item.operational_hours[4].opening_time,
              closing_time: item.operational_hours[4].closing_time,
              is_holiday: item.operational_hours[4].is_holiday,
            },
            {
              day: item.operational_hours[5].day,
              opening_time: item.operational_hours[5].opening_time,
              closing_time: item.operational_hours[5].closing_time,
              is_holiday: item.operational_hours[5].is_holiday,
            },
            {
              day: item.operational_hours[6].day,
              opening_time: item.operational_hours[6].opening_time,
              closing_time: item.operational_hours[6].closing_time,
              is_holiday: item.operational_hours[6].is_holiday,
            },
          ],
        })
      );
      this.getUsers(this.editId);
      this.$bvModal.show("modal-cabang");
    },
    getUsers() {
      const filter = {
        roles: ["Kasir"],
        branch_uuid: this.editId,
      };
      this.$store
        .dispatch("users/getData", { uuid: "", params: filter })
        .then((result) => {
          this.userList = result.data.data.data.map((e) => {
            return {
              id: e.id,
              name: e.name,
            };
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    searchCountry: _.debounce(function (e) {
      const search = e.target.value;
      this.searchQuery = search;
      this.getCountry(search);
    }, 300),
    getCountry(search) {
      const isDialCode = /^\d+$/.test(search);
      const params = isDialCode ? { search: search } : { search: search };

      this.$http
        .get("/country-phone-codes?per_page=500", { params })
        .then((response) => {
          this.countryList = response.data.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getData(page = 1) {
      this.isLoading = true;
      const queryParams = this.filter;
      queryParams.page = page;
      this.$store
        .dispatch("cabang/getData", { uuid: "", params: queryParams })
        .then((result) => {
          this.isLoading = false;
          this.result = result.data;
          this.currentPage = result.data.current_page;
          this.getProfiles();
          this.getCountry();
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    getProfiles() {
      this.$store
        .dispatch("profile/getProfile")
        .then((response) => {
          this.slotAddBranch = response.data.data.merchant.slot_add_branch;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    createItem() {
      this.$swal({
        title: "Konfirmasi",
        text: "Apakah Anda yakin ingin membuat Cabang baru?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.formPayload.address_latitude =
            this.selectedLocation.position.lat;
          this.formPayload.address_longitude =
            this.selectedLocation.position.lng;

          const form = this.preparePayload();
          this.isLoading = true;
          this.$store
            .dispatch("cabang/postData", {
              query: "",
              payload: form,
            })
            .then(() => {
              this.getData();
              successNotification(this, "Success", "Cabang berhasil dibuat");
              this.cleanUpForm();
              this.$bvModal.hide("modal-cabang");
              this.isLoading = false;
            })
            .catch((error) => {
              this.messages = error.response.data.meta.messages;
              this.isLoading = false;
            });
        }
      });
    },
    updateItem() {
      this.$swal({
        title: "Konfirmasi",
        text: "Apakah Anda yakin ingin mengubah data Cabang ini?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.formPayload.address_latitude =
            this.selectedLocation.position.lat;
          this.formPayload.address_longitude =
            this.selectedLocation.position.lng;

          const form = this.preparePayload();
          this.isLoading = true;
          form.append("_method", "PATCH");

          this.$store
            .dispatch("cabang/postData", {
              query: `${this.editId}`,
              payload: form,
            })
            .then(() => {
              this.getData();
              successNotification(this, "Success", "Cabang berhasil diubah!");
              this.cleanUpForm();
              this.isLoading = false;
              this.$bvModal.hide("modal-cabang");
              window.location.reload();
            })
            .catch((error) => {
              this.messages = error.response.data.meta.messages;
              this.isLoading = false;
            });
        }
      });
    },
    async deleteData(uuid) {
      this.$swal({
        title: "Konfirmasi",
        text: "Apakah Anda yakin ingin menghapus Cabang ini?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("cabang/deleteData", `${uuid}`)
            .then(() => {
              this.getData();
              this.$swal({
                icon: "success",
                title: "Sukses!",
                text: "Cabang berhasil dihapus",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            })
            .catch((error) => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(
                  this,
                  "Oops!",
                  error.response.data.meta.messages
                );
              }
            });
        }
      });
    },
    preparePayload() {
      if (this.formPayload.enable_petty_cash === "0") {
        this.formPayload.petty_cash_seperate = "no";
      }
      this.formPayload.selectedCountry != null
        ? (this.formPayload.phone_country =
            this.formPayload.selectedCountry.country_code)
        : "";
      const { petty_cash_seperate, ...formPayloadWithoutPettyCashSeperate } =
        this.formPayload;
      formPayloadWithoutPettyCashSeperate.petty_cash_permissions =
        petty_cash_seperate === "yes"
          ? formPayloadWithoutPettyCashSeperate.petty_cash_permissions
          : [];

      const form = new FormData();

      for (const key in formPayloadWithoutPettyCashSeperate) {
        if (formPayloadWithoutPettyCashSeperate.hasOwnProperty(key)) {
          if (key === "operational_hours") {
            formPayloadWithoutPettyCashSeperate[key].forEach((hour, index) => {
              Object.keys(hour).forEach((hourKey) => {
                const fieldName = `operational_hours[${index}][${hourKey}]`;

                if (hourKey === "is_holiday") {
                  form.append(fieldName, hour[hourKey] ? "1" : "0");
                } else {
                  form.append(fieldName, hour[hourKey]);
                }
              });
            });
          } else if (key === "petty_cash_permissions") {
            formPayloadWithoutPettyCashSeperate[key].forEach((cash, index) => {
              Object.keys(cash).forEach((key) => {
                if (key !== "operator_name") {
                  form.append(
                    "petty_cash_permissions" + "[" + index + "]",
                    cash[key]
                  );
                }
              });
            });
          } else if (key != "selectedCountry") {
            form.append(key, formPayloadWithoutPettyCashSeperate[key]);
          }
        }
      }

      return form;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.px-33 {
  padding-left: 33px;
  padding-right: 33px;
}
.px-16 {
  padding-left: 16px;
  padding-right: 16px;
}
.py-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}
.py-18 {
  padding-top: 18px;
  padding-bottom: 18px;
}

#app {
  background: #f6f7fa;
}

.master-toko {
  height: 100vh;
}
</style>

<template>
  <b-modal
    id="modal-cabang"
    hide-header
    hide-footer
    ok-only
    ok-title="Accept"
    centered
    size="lg"
    title="Large Modal"
    class="custom__modal--large"
  >
    <div class="section">
      <header
        class="sticky-top border border-light-bottom border-light-line border-light-top border-light-line p-2"
      >
        <b-row>
          <b-col cols="3" class="d-flex align-items-center">
            <div style="cursor: pointer" @click="directPage">
              <feather-icon
                size="24"
                class="text-dark font-weight-bolder"
                icon="ArrowLeftIcon"
              />
              <span class="text-dark text-darken-4 fw-bold-600 size14 ml-1"
                >Kembali</span
              >
            </div>
          </b-col>
          <b-col
            cols="6"
            class="d-flex justify-content-center align-items-center"
          >
            <h1 class="text-dark h6 font-weight-bolder mb-0 text-center">
              Informasi Cabang
            </h1>
          </b-col>
          <b-col cols="3" class="text-right">
            <b-button
              class="px-3"
              @click="editUuid == null ? createItem() : updateItem()"
              :disabled="
                !formPayload.code_branch ||
                !formPayload.name ||
                formPayload.phone == '' ||
                formPayload.operational_hours[0].opening_time == '' ||
                formPayload.operational_hours[0].closing_time == '' ||
                formPayload.operational_hours[1].opening_time == '' ||
                formPayload.operational_hours[1].closing_time == '' ||
                formPayload.operational_hours[2].opening_time == '' ||
                formPayload.operational_hours[2].closing_time == '' ||
                formPayload.operational_hours[3].opening_time == '' ||
                formPayload.operational_hours[3].closing_time == '' ||
                formPayload.operational_hours[4].opening_time == '' ||
                formPayload.operational_hours[4].closing_time == '' ||
                formPayload.operational_hours[5].opening_time == '' ||
                formPayload.operational_hours[5].closing_time == '' ||
                formPayload.operational_hours[6].opening_time == '' ||
                formPayload.operational_hours[6].closing_time == ''
              "
            >
              <b-spinner small v-if="isLoading" />
              Simpan
            </b-button>
          </b-col>
        </b-row>
      </header>
      <b-container class="mt-5 pt-5">
        <b-row class="justify-content-center h-100 bg-white pb-5">
          <b-col cols="12" sm="12" md="7">
            <b-row>
              <b-col cols="12" class="text-center mb-2">
                <UploadPhoto
                  v-if="photo_url == ''"
                  :label-for="'upload_photo'"
                  :style-name="'width:140px; height:140px; margin: 0 auto; background-size:contain;'"
                  :photo-url-parent="'asdasd'"
                  @photo="getPhoto"
                />
                <div v-else>
                  <div
                    class="box__upload--photo mx-auto"
                    :style="`background-image: url(${photo_url});width: 140px;height: 140px; margin: 0 auto; background-size: contain;`"
                    @click="clearPhoto"
                  >
                    <b-avatar class="floating__close--button">
                      <feather-icon class="text-danger" icon="XIcon" />
                    </b-avatar>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <!-- Nama Gudang -->
              <b-col cols="12" md="12" class="mb-2 custom__form--input">
                <label for="v-nama-gudang"
                  >Nama <span class="text-danger">*</span></label
                >
                <b-form-group>
                  <b-form-input
                    id="v-nama-gudang"
                    v-model="formPayload.name"
                    type="text"
                    placeholder="Nama"
                    class="custom__input"
                  />
                  <small
                    v-if="
                      messagesChild &&
                      messagesChild.name &&
                      Array.isArray(messagesChild.name) &&
                      messagesChild.name.length > 0
                    "
                    class="text-danger"
                    >{{ messagesChild.name.join(", ") }}</small
                  >
                </b-form-group>
              </b-col>
              <!-- Alamat Barang -->
              <b-col cols="12" md="12" class="mb-3 custom__form--input">
                <label for="v-address">Alamat</label>
                <b-form-group>
                  <b-form-textarea
                    id="v-address"
                    v-model="formPayload.address"
                    rows="5"
                    placeholder="Alamat"
                    class="custom__textarea"
                  />
                </b-form-group>
              </b-col>
              <!-- Kota -->
              <b-col cols="12" md="12" class="mb-2 custom__form--input">
                <label for="v-kota">Kota </label>
                <b-form-group>
                  <b-form-input
                    id="v-kota"
                    v-model="formPayload.city"
                    type="text"
                    placeholder="Kota"
                    class="custom__input"
                  />
                  <small
                    v-if="
                      messagesChild &&
                      messagesChild.city &&
                      Array.isArray(messagesChild.city) &&
                      messagesChild.city.length > 0
                    "
                    class="text-danger"
                    >{{ messagesChild.city.join(", ") }}</small
                  >
                </b-form-group>
              </b-col>
              <!-- Kode Cabang -->
              <b-col cols="12" md="12" class="mb-2 custom__form--input">
                <label for="v-code__cabang"
                  >Kode Cabang <span class="text-danger">*</span>
                </label>
                <b-form-group>
                  <b-form-input
                    id="v-code__cabang"
                    v-model="formPayload.code_branch"
                    type="text"
                    placeholder="Kode Cabang"
                    class="custom__input"
                  />
                </b-form-group>
              </b-col>
              <!-- Phone Cabang -->
              <b-col cols="12" md="12" class="mb-2 custom__form--input">
                <label for="v-phone"
                  >Telpon Cabang <span class="text-danger">*</span></label
                >
                <b-row>
                  <b-col cols="3" class="pr-0">
                    <v-select
                      v-model="formPayload.selectedCountry"
                      :label="currentLabel"
                      :options="countryListData"
                      :clearable="false"
                      @keyup.stop.native="searchCountry"
                    >
                      <template
                        #selected-option="{ country_flag, dial_code_preview }"
                      >
                        <div class="d-flex align-items-center" style="gap: 6px">
                          <img
                            :src="country_flag"
                            :alt="dial_code_preview"
                            style="width: 24px; height: 24px"
                          />
                          <p class="size12 mb-0">({{ dial_code_preview }})</p>
                        </div>
                      </template>
                      <template #option="{ country_flag, dial_code_preview }">
                        <div class="d-flex align-items-center" style="gap: 8px">
                          <img
                            :src="country_flag"
                            :alt="dial_code_preview"
                            style="width: 24px; height: 24px"
                          />
                          <p class="mb-0">{{ dial_code_preview }}</p>
                        </div>
                      </template>
                    </v-select>
                  </b-col>
                  <b-col cols="9">
                    <b-form-group>
                      <b-form-input
                        id="v-phone"
                        v-model="formattedPhone"
                        type="tel"
                        placeholder="Telpon Cabang"
                        class="custom__input"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <!-- Kode Pos -->
              <b-col cols="12" md="12" class="mb-2 custom__form--input">
                <label for="v-postal-code">Kode Pos </label>
                <b-form-group>
                  <b-form-input
                    id="v-postal-code"
                    v-model="formPayload.postal_code"
                    type="number"
                    placeholder="Kode Pos"
                    class="custom__input"
                  />
                </b-form-group>
              </b-col>
              <!-- Notes -->
              <b-col cols="12" md="12" class="mb-2 custom__form--input">
                <label for="v-notes">Note di Struk Penjualan</label>
                <b-form-group>
                  <b-form-input
                    id="v-notes"
                    v-model="formPayload.order_invoice_notes"
                    type="text"
                    placeholder="Notes"
                    class="custom__input"
                  />
                </b-form-group>
              </b-col>
              <!-- PPN 11 -->
              <b-col
                cols="12"
                md="12"
                class="pb-2 mb-2 custom__form--input d-flex align-items-center justify-content-between border-bottom"
              >
                <label class="mb-0"
                  >Tampilkan Note "Harga sudah termasuk PPn 11%" di struk
                  penjualan
                </label>
                <b-form-group class="mb-0">
                  <div class="d-flex align-items-center" style="gap: 12px">
                    <b-form-radio
                      v-model="formPayload.display_vat_on_order_invoice"
                      name="ppn"
                      value="1"
                    >
                      Iya
                    </b-form-radio>
                    <b-form-radio
                      v-model="formPayload.display_vat_on_order_invoice"
                      name="ppn"
                      value="0"
                    >
                      Tidak
                    </b-form-radio>
                  </div>
                </b-form-group>
              </b-col>
              <!-- Izin Transaksi -->
              <b-col
                cols="12"
                md="12"
                class="pb-2 mb-2 custom__form--input d-flex align-items-center justify-content-between border-bottom"
              >
                <label class="mb-0">Izinkan transaksi stok minus </label>
                <b-form-group class="mb-0">
                  <div class="d-flex align-items-center" style="gap: 12px">
                    <b-form-radio
                      v-model="formPayload.allow_negative_stock"
                      name="transaction"
                      value="1"
                    >
                      Iya
                    </b-form-radio>
                    <b-form-radio
                      v-model="formPayload.allow_negative_stock"
                      name="transaction"
                      value="0"
                    >
                      Tidak
                    </b-form-radio>
                  </div>
                </b-form-group>
              </b-col>
              <!-- Tampillan Stok -->
              <b-col
                cols="12"
                md="12"
                class="pb-2 mb-2 custom__form--input d-flex align-items-center justify-content-between border-bottom"
              >
                <label class="mb-0">Tampilkan stok di toko digital </label>
                <b-form-group class="mb-0">
                  <div class="d-flex align-items-center" style="gap: 12px">
                    <b-form-radio
                      v-model="formPayload.display_stock_ecommerce"
                      name="stock"
                      value="1"
                    >
                      Iya
                    </b-form-radio>
                    <b-form-radio
                      v-model="formPayload.display_stock_ecommerce"
                      name="stock"
                      value="0"
                    >
                      Tidak
                    </b-form-radio>
                  </div>
                </b-form-group>
              </b-col>
              <!-- Gunakan Kas Kecil -->
              <template v-if="editUuid !== null">
                <b-col
                  cols="12"
                  md="12"
                  class="pb-2 mb-2 custom__form--input d-flex align-items-center justify-content-between border-bottom"
                >
                  <label class="mb-0">Gunakan Fitur Kas Kecil</label>
                  <b-form-group class="mb-0">
                    <div class="d-flex align-items-center" style="gap: 12px">
                      <b-form-radio
                        v-model="formPayload.enable_petty_cash"
                        name="kas"
                        value="1"
                      >
                        Iya
                      </b-form-radio>
                      <b-form-radio
                        v-model="formPayload.enable_petty_cash"
                        name="kas"
                        value="0"
                      >
                        Tidak
                      </b-form-radio>
                    </div>
                  </b-form-group>
                </b-col>
                <!-- Tampilkan pengguna menggunakan kas kecil terpisan -->
                <template v-if="formPayload.enable_petty_cash === '1'">
                  <b-col
                    cols="12"
                    md="12"
                    class="pb-2 mb-2 custom__form--input d-flex align-items-center justify-content-between border-bottom"
                  >
                    <label class="mb-0">Gunakan Multi Kas Kecil</label>
                    <b-form-group class="mb-0">
                      <div class="d-flex align-items-center" style="gap: 12px">
                        <b-form-radio
                          v-model="formPayload.petty_cash_seperate"
                          name="seperated-kas"
                          value="yes"
                        >
                          Iya
                        </b-form-radio>
                        <b-form-radio
                          v-model="formPayload.petty_cash_seperate"
                          name="seperated-kas"
                          value="no"
                        >
                          Tidak
                        </b-form-radio>
                      </div>
                    </b-form-group>
                  </b-col>
                  <template v-if="formPayload.petty_cash_seperate === 'yes'">
                    <b-col cols="12" class="custom__form--input">
                      <div
                        class="d-flex align-items-center mb-2"
                        v-for="(
                          item, index
                        ) in formPayload.petty_cash_permissions"
                        :key="index"
                      >
                        <b-form-group class="custom__form--input w-100 mb-0">
                          <label for="value-diskon"
                            >Pilih Pengguna Untuk Membuka Kas Kecil</label
                          >
                          <v-select
                            v-model="
                              formPayload.petty_cash_permissions[index]
                                .operator_id
                            "
                            label="name"
                            :options="userListData"
                            placeholder="Pilih User"
                            :reduce="(users) => users.id"
                          />
                        </b-form-group>
                        <b-button
                          id="button--hapus__biaya"
                          class="p-1 ml-25"
                          variant="outline-danger"
                          style="align-self: end"
                          @click="removeItem(index)"
                        >
                          <feather-icon icon="Trash2Icon" />
                        </b-button>
                      </div>
                    </b-col>
                    <b-col cols="12" class="mb-2 custom__form--input">
                      <b-button
                        id="button--tambah__biaya"
                        class="w-100"
                        variant="outline-dark"
                        @click="repeateAgain()"
                        :disabled="
                          formPayload.petty_cash_permissions.length ===
                          userListData.length
                        "
                      >
                        + Tambah User
                      </b-button>
                    </b-col>
                  </template>
                </template>
              </template>
              <!-- Jam Operational Toko -->
              <b-col cols="3" class="mb-2 custom__form--input">
                <label>Hari <span class="text-danger">*</span></label>
                <b-form-group>
                  <b-form-input
                    :value="'Minggu'"
                    type="text"
                    class="custom__input text-capitalize"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col cols="3" class="mb-2 custom__form--input">
                <label for="v-opening-time"
                  >Jam Buka <span class="text-danger">*</span></label
                >
                <b-form-group>
                  <b-form-input
                    v-model="formPayload.operational_hours[0].opening_time"
                    type="time"
                    class="custom__input"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="3" class="mb-2 custom__form--input">
                <label>Jam Tutup <span class="text-danger">*</span></label>
                <b-form-group>
                  <b-form-input
                    v-model="formPayload.operational_hours[0].closing_time"
                    type="time"
                    class="custom__input"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="3" class="mb-2 custom__form--input">
                <label>Buka / Tutup <span class="text-danger">*</span></label>
                <b-form-group>
                  <v-select
                    v-model="formPayload.operational_hours[0].is_holiday"
                    :reduce="(option) => option.id"
                    :options="[
                      { label: 'Buka', id: false },
                      { label: 'Tutup', id: true },
                    ]"
                    class="border border-8"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="3" class="mb-2 custom__form--input">
                <b-form-group>
                  <b-form-input
                    :value="'Senin'"
                    type="text"
                    class="custom__input text-capitalize"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col cols="3" class="mb-2 custom__form--input">
                <b-form-group>
                  <b-form-input
                    v-model="formPayload.operational_hours[1].opening_time"
                    type="time"
                    class="custom__input"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="3" class="mb-2 custom__form--input">
                <b-form-group>
                  <b-form-input
                    v-model="formPayload.operational_hours[1].closing_time"
                    type="time"
                    class="custom__input"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="3" class="mb-2 custom__form--input">
                <b-form-group>
                  <v-select
                    v-model="formPayload.operational_hours[1].is_holiday"
                    :reduce="(option) => option.id"
                    :options="[
                      { label: 'Buka', id: false },
                      { label: 'Tutup', id: true },
                    ]"
                    class="border border-8"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="3" class="mb-2 custom__form--input">
                <b-form-group>
                  <b-form-input
                    :value="'Selasa'"
                    type="text"
                    class="custom__input text-capitalize"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col cols="3" class="mb-2 custom__form--input">
                <b-form-group>
                  <b-form-input
                    v-model="formPayload.operational_hours[2].opening_time"
                    type="time"
                    class="custom__input"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="3" class="mb-2 custom__form--input">
                <b-form-group>
                  <b-form-input
                    v-model="formPayload.operational_hours[2].closing_time"
                    type="time"
                    class="custom__input"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="3" class="mb-2 custom__form--input">
                <b-form-group>
                  <v-select
                    v-model="formPayload.operational_hours[2].is_holiday"
                    :reduce="(option) => option.id"
                    :options="[
                      { label: 'Buka', id: false },
                      { label: 'Tutup', id: true },
                    ]"
                    class="border border-8"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="3" class="mb-2 custom__form--input">
                <b-form-group>
                  <b-form-input
                    :value="'Rabu'"
                    type="text"
                    class="custom__input text-capitalize"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col cols="3" class="mb-2 custom__form--input">
                <b-form-group>
                  <b-form-input
                    v-model="formPayload.operational_hours[3].opening_time"
                    type="time"
                    class="custom__input"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="3" class="mb-2 custom__form--input">
                <b-form-group>
                  <b-form-input
                    v-model="formPayload.operational_hours[3].closing_time"
                    type="time"
                    class="custom__input"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="3" class="mb-2 custom__form--input">
                <b-form-group>
                  <v-select
                    v-model="formPayload.operational_hours[3].is_holiday"
                    :reduce="(option) => option.id"
                    :options="[
                      { label: 'Buka', id: false },
                      { label: 'Tutup', id: true },
                    ]"
                    class="border border-8"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="3" class="mb-2 custom__form--input">
                <b-form-group>
                  <b-form-input
                    :value="'Kamis'"
                    type="text"
                    class="custom__input text-capitalize"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col cols="3" class="mb-2 custom__form--input">
                <b-form-group>
                  <b-form-input
                    v-model="formPayload.operational_hours[4].opening_time"
                    type="time"
                    class="custom__input"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="3" class="mb-2 custom__form--input">
                <b-form-group>
                  <b-form-input
                    v-model="formPayload.operational_hours[4].closing_time"
                    type="time"
                    class="custom__input"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="3" class="mb-2 custom__form--input">
                <b-form-group>
                  <v-select
                    v-model="formPayload.operational_hours[4].is_holiday"
                    :reduce="(option) => option.id"
                    :options="[
                      { label: 'Buka', id: false },
                      { label: 'Tutup', id: true },
                    ]"
                    class="border border-8"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="3" class="mb-2 custom__form--input">
                <b-form-group>
                  <b-form-input
                    :value="'Jumat'"
                    type="text"
                    class="custom__input text-capitalize"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col cols="3" class="mb-2 custom__form--input">
                <b-form-group>
                  <b-form-input
                    v-model="formPayload.operational_hours[5].opening_time"
                    type="time"
                    class="custom__input"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="3" class="mb-2 custom__form--input">
                <b-form-group>
                  <b-form-input
                    v-model="formPayload.operational_hours[5].closing_time"
                    type="time"
                    class="custom__input"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="3" class="mb-2 custom__form--input">
                <b-form-group>
                  <v-select
                    v-model="formPayload.operational_hours[5].is_holiday"
                    :reduce="(option) => option.id"
                    :options="[
                      { label: 'Buka', id: false },
                      { label: 'Tutup', id: true },
                    ]"
                    class="border border-8"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="3" class="mb-2 custom__form--input">
                <b-form-group>
                  <b-form-input
                    :value="'Sabtu'"
                    type="text"
                    class="custom__input text-capitalize"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col cols="3" class="mb-2 custom__form--input">
                <b-form-group>
                  <b-form-input
                    v-model="formPayload.operational_hours[6].opening_time"
                    type="time"
                    class="custom__input"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="3" class="mb-2 custom__form--input">
                <b-form-group>
                  <b-form-input
                    v-model="formPayload.operational_hours[6].closing_time"
                    type="time"
                    class="custom__input"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="3" class="mb-2 custom__form--input">
                <b-form-group>
                  <v-select
                    v-model="formPayload.operational_hours[6].is_holiday"
                    :reduce="(option) => option.id"
                    :options="[
                      { label: 'Buka', id: false },
                      { label: 'Tutup', id: true },
                    ]"
                    class="border border-8"
                  />
                </b-form-group>
              </b-col>

              <!-- Stok minus
              <b-col cols="12" md="12" class="custom__form--input mb-2">
                <b-form-checkbox
                  v-model="formPayload.allow_negative_stock"
                  :value="true"
                  :unchecked-value="false"
                >
                  Izinkan transaksi dengan stok minus
                </b-form-checkbox>
              </b-col>

              Stok minus
              <b-col cols="12" md="12" class="custom__form--input mb-2">
                <b-form-checkbox
                  v-model="formPayload.display_stock_ecommerce"
                  :value="true"
                  :unchecked-value="false"
                >
                  Tampilkan stok di toko digital
                </b-form-checkbox>
              </b-col> -->

              <!-- Map -->
              <b-col cols="12" md="12" class="custom__form--input">
                <label for="">Pin Lokasi di Peta</label>
                <GeolocationSelectorMap
                  :key="1"
                  v-model="selectedLocation"
                  :default-location="currentLocation"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </b-modal>
</template>
<script>
/* eslint-disable vue/require-default-prop */
import {
  BModal,
  BContainer,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BFormTextarea,
  BAvatar,
  BSpinner,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BFormRadio,
} from "bootstrap-vue";
// import { VueTelInput } from "vue-tel-input";

import UploadPhoto from "@/components/UploadPhoto.vue";
import GeolocationSelectorMap from "@/components/GeolocationSelectorMap.vue";
import Maps from "@/components/Maps.vue";
import vSelect from "vue-select";

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BFormTextarea,
    BContainer,
    UploadPhoto,
    BAvatar,
    BSpinner,
    GeolocationSelectorMap,
    Maps,
    vSelect,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BFormRadio,
    // VueTelInput,
  },
  props: {
    formData: {
      type: "",
    },
    searchCountry: {
      type: Function,
    },
    userList: {
      type: Array,
    },
    countryList: {
      type: Array,
    },
    searchQuery: {
      type: String,
    },
    createItem: {
      type: Function,
    },
    editItem: {
      type: Function,
    },
    editId: {
      type: String,
    },
    updateItem: {
      type: Function,
    },
    cleanUpForm: {
      type: Function,
    },
    photo: {
      type: String,
    },
    messages: {
      type: Object,
    },
    result: {
      type: Object,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formPayload: {
        name: "",
        city: "",
        photo: "",
        address: "",
        phone: "",
        postal_code: "",
        selectedCountry: {
          country_code: "ID",
          country_flag: "https://flagsapi.com/ID/shiny/64.png",
          country_name: "Indonesia",
          dial_code_preview: "+62",
          dial_code_value: "62",
        },
        address_latitude: "",
        address_longitude: "",
        display_vat_on_order_invoice: false,
        allow_negative_stock: false,
        display_stock_ecommerce: false,
        order_invoice_notes: "",
        operational_hours: [
          {
            day: "sunday",
            opening_time: "",
            closing_time: "",
            is_holiday: false,
          },
          {
            day: "monday",
            opening_time: "",
            closing_time: "",
            is_holiday: false,
          },
          {
            day: "tuesday",
            opening_time: "",
            closing_time: "",
            is_holiday: false,
          },
          {
            day: "wednesday",
            opening_time: "",
            closing_time: "",
            is_holiday: false,
          },
          {
            day: "thursday",
            opening_time: "",
            closing_time: "",
            is_holiday: false,
          },
          {
            day: "friday",
            opening_time: "",
            closing_time: "",
            is_holiday: false,
          },
          {
            day: "saturday",
            opening_time: "",
            closing_time: "",
            is_holiday: false,
          },
        ],
      },
      isDeleting: false,
      photo_url: "",
      editUuid: null,
      messagesChild: "",
      selectedLocation: {},
      currentLocation: {
        lat: "",
        lng: "",
      },
      userListData: [],
      countryListData: [],
      searchQueryData: "",
    };
  },
  computed: {
    currentLabel() {
      return /^\d+$/.test(this.searchQueryData)
        ? "dial_code_value"
        : "country_name";
    },
    dialCode() {
      const selectedCountry = this.countryListData.find(
        (country) =>
          country.country_code ===
          this.formPayload.selectedCountry?.country_code
      );
      return selectedCountry ? selectedCountry.dial_code_value : "62";
    },
    formattedPhone: {
      get() {
        if (!this.formPayload.phone) {
          return "";
        }
        const regex = new RegExp(`^${this.dialCode}`);
        return this.formPayload.phone.replace(regex, "");
      },
      set(value) {
        if (value.startsWith("0")) {
          value = value.substring(1);
        }
        this.formPayload.phone = this.dialCode + value;
      },
    },
  },
  watch: {
    "formPayload.selectedCountry"(newVal, oldVal) {
      const oldDialCode = oldVal ? oldVal.dial_code_value : "";
      const newDialCode = newVal.dial_code_value;
      if (this.formPayload.phone.startsWith(oldDialCode)) {
        this.formPayload.phone =
          newDialCode + this.formPayload.phone.replace(oldDialCode, "");
      }
    },
    userList(value) {
      this.userListData = value;
    },
    countryList(value) {
      this.countryListData = value;
    },
    searchQuery(value) {
      this.searchQueryData = value;
    },
    "formPayload.phone"(newVal) {
      this.formPayload.phone = newVal.replace(/[^0-9]/g, "");
    },
    // "formPayload.phone"(newValue, oldValue) {
    //   if (this.isDeleting) {
    //     this.isDeleting = false;
    //     return;
    //   }

    //   if (newValue.length < oldValue.length && !newValue.startsWith("62")) {
    //     this.formPayload.phone = "62" + newValue;
    //     this.isDeleting = true;
    //   }
    // },
    photo(value) {
      this.photo_url = value;
    },
    editId(value) {
      this.editUuid = value;
    },
    formData(value) {
      this.currentLocation = {
        lat: value.address_latitude || this.currentLocation.lat || "",
        lng: value.address_longitude || this.currentLocation.lng || "",
      };
      // this.phone = value.phone;
      this.formPayload = value;
    },
    formPayload: {
      handler(value) {
        this.$emit("getPayload", value);
      },
      deep: true,
    },
    messages: {
      handler(value) {
        this.messagesChild = value;
      },
      deep: true,
    },
    selectedLocation(value) {
      this.$emit("update:selectedLocation", value);
    },
  },
  mounted() {
    // this.getUsers();
  },
  methods: {
    clearPhoto() {
      this.photo_url = "";
      this.formPayload.photo = "";
    },
    getPhoto(value) {
      this.formPayload.photo = value.photo;
    },
    directPage() {
      this.messagesChild = {};
      this.cleanUpForm();
      this.$bvModal.hide("modal-cabang");
    },
    repeateAgain() {
      this.formPayload.petty_cash_permissions.push({
        operator_id: "",
      });
    },
    removeItem(index) {
      this.formPayload.petty_cash_permissions.splice(index, 1);
    },
    // getUsers() {
    //   const filter = {
    //     roles: ["Kasir"],
    //     branch_uuid: editUuid,
    //   };
    //   this.$store
    //     .dispatch("users/getData", { uuid: "", params: filter })
    //     .then((result) => {
    //       this.userList = result.data.data.data.map((e) => {
    //         return {
    //           id: e.id,
    //           name: e.name,
    //         };
    //       });
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    // handlePhoneNumberInput(value) {
    //   if (!value) {
    //     this.phone = "62";
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
.box__upload--photo {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #eff1f5;
  border: 1px dashed #7a7f94;
  border-radius: 8px;
  position: relative;

  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;

  width: 195px;
  height: 140px;

  .floating__close--button {
    position: absolute;
    top: 6px;
    width: 24px;
    height: 24px;
    right: 6px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";
</style>

<!-- <style src="vue-tel-input/dist/vue-tel-input.css"></style> -->
